import * as React from "react";
import { type HeadProps } from "gatsby";

import Layout from "../components/campaign/Layout";
import CampaignHero from "../components/campaign/CampaignHero";
import SEO from "../components/common/SEO";
import Faq from "../components/common/Faq";
import JobPosition from "../components/campaign/JobPosition";
import Simgum from "../components/campaign/Simgum";
import Schedule from "../components/campaign/Schedule";
import CardSection from "../components/campaign/CardSection";
import Story from "../components/campaign/Story";
import Benefits from "../components/teaser/Benefits";
import Procedure from "../components/campaign/Procedure";
import GNB from "../components/campaign/GNB";
import StickyButton from "../components/campaign/StickyButton";

const TeaserPage = () => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [visible, setVisible] = React.useState<boolean>(false);

  const handleScroll = () => {
    if (ref.current) {
      const rect = ref.current.getBoundingClientRect();
      if (rect.top <= -2060) {
        setVisible(true);
      } else {
        setVisible(false);
      }
    }
  };

  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      <Layout>
        <article ref={ref}>
          <GNB visible={visible} />
          <CampaignHero />
          <Story />
          <CardSection />
          <Benefits />
          <Schedule />
          <Procedure />
          <JobPosition />
          <Faq />
          <Simgum />
        </article>
      </Layout>
      {visible && <StickyButton visible={visible} />}
    </>
  );
};

export const Head = ({ location }: HeadProps) => {
  return <SEO location={location} />;
};

export default TeaserPage;
